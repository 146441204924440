var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ManagementLayout',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Flow Groups")]},proxy:true},{key:"subtitle",fn:function(){return [(_vm.permissionsCheck)?_c('span',[_vm._v(" View and manage your team's flows by "),_c('ExternalLink',{attrs:{"href":"https://docs.prefect.io/cloud/concepts/flows.html#versioning"}},[_vm._v("version group")])],1):_c('span',[_vm._v("View the "),_c('ExternalLink',{attrs:{"href":"https://docs.prefect.io/cloud/concepts/flows.html#versioning"}},[_vm._v("flow groups")]),_vm._v(" of "+_vm._s(_vm.tenant.name))],1)]},proxy:true}])},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-text-field',{staticClass:"rounded-0 elevation-1 mt-2 mb-1",attrs:{"solo":"","dense":"","hide-details":"","single-line":"","placeholder":"Search by flow name, project name or creator","prepend-inner-icon":"search","autocomplete":"new-password"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-card',{class:{ 'mt-3': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"tile":""}},[_c('v-card-text',{staticClass:"pa-0"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"py-1 mr-2 flex"},[_c('v-text-field',{staticClass:"rounded-0 elevation-1",style:({
            'max-width': _vm.$vuetify.breakpoint.mdAndUp ? '420px' : null
          }),attrs:{"solo":"","dense":"","hide-details":"","single-line":"","placeholder":"Search by flow name, project name or creator","prepend-inner-icon":"search","autocomplete":"new-password"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-2 rounded-0 truncate-table",class:{ 'fixed-table': _vm.$vuetify.breakpoint.smAndUp },attrs:{"fixed-header":"","search":_vm.search,"header-props":{ 'sort-icon': 'arrow_drop_up' },"items":_vm.items,"headers":_vm.headers,"loading":_vm.loadingKey > 0,"items-per-page":10,"footer-props":{
          showFirstLastPage: true,
          itemsPerPageOptions: [10, 15, 20, -1],
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'keyboard_arrow_left',
          nextIcon: 'keyboard_arrow_right'
        }},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
        var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"header.active",fn:function(ref){
        var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"header.version_group_id",fn:function(ref){
        var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"header.created_by.username",fn:function(ref){
        var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"header.project.name",fn:function(ref){
        var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"item.version_group_id",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({staticClass:"hidewidth cursor-pointer",on:{"click":function($event){return _vm.copyTextToClipboard(item.version_group_id)}}},on),[(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(" "+_vm._s(item.version_group_id)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.version_group_id.substring(0, 3))+"... ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.copiedFvgId === item.version_group_id ? 'Copied!' : 'Click to copy ID'))])])]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [(item.name)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({staticClass:"hidewidth"},on),[_c('router-link',{attrs:{"to":{
                    name: 'flow',
                    params: {
                      id: item.id,
                      tenant: _vm.tenant.slug
                    },
                    query: { versions: '' }
                  }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])]):_c('span',[_vm._v("-")])]}},{key:"item.active",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(item.active)?_c('v-icon',_vm._g({attrs:{"small":"","dark":"","color":"green"}},on),[_vm._v(" pi-flow ")]):_c('v-icon',_vm._g({attrs:{"small":"","dark":"","color":"accentPink"}},on),[_vm._v(" archive ")])]}}],null,true)},[(item.active)?_c('span',[_vm._v(" This version group has an active flow ")]):_c('span',[_vm._v(" All flows in this version group are archived ")])])]}},(_vm.permissionsCheck)?{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error","text":"","fab":"","x-small":""},on:{"click":function($event){_vm.dialogDeleteFvg = true
              _vm.selectFvg(item)}}},[_c('v-icon',[_vm._v("delete")])],1)]}}:null],null,true)})],1)],1),(_vm.selectedFvg)?_c('ConfirmDialog',{attrs:{"type":"error","dialog-props":{ 'max-width': '500' },"disabled":_vm.isDeletingFvg,"loading":_vm.isDeletingFvg,"title":("Are you sure you want to delete the version group for " + (_vm.selectedFvg.name) + "?")},on:{"cancel":_vm.cancelFvgDelete,"confirm":_vm.deleteFvg},model:{value:(_vm.dialogDeleteFvg),callback:function ($$v) {_vm.dialogDeleteFvg=$$v},expression:"dialogDeleteFvg"}},[_vm._v(" This will delete "),_c('span',{staticClass:"font-weight-black"},[_vm._v("all")]),_vm._v(" versions of your flow and cannot be undone. ")]):_vm._e(),_c('Alert',{attrs:{"type":_vm.alertType,"message":_vm.alertMessage,"offset-x":_vm.$vuetify.breakpoint.mdAndUp ? 256 : 56},model:{value:(_vm.alertShow),callback:function ($$v) {_vm.alertShow=$$v},expression:"alertShow"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }